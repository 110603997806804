body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.image-container {
  position: relative;
  text-align: center;
  padding: 5px;
  display: inline-block;
  position: relative; /* Ensure relative positioning for the container */
}

.delete-button {
  display: none;
  position: absolute;
  top: 0; /* Position the button at the top */
  right: 0; /* Position the button at the left */
  color: rgb(248, 2, 2);
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

.image-container:hover .delete-button {
  display: inline-block;
}
